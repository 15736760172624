<app-header></app-header>

<div class="tile is-ancestor" style="padding-top:10px;">
  <div class="tile is-parent is-4">
    <div class="tile is-child box">
      <div class="content">
        <iframe width="300" height="200" src="https://www.youtube.com/embed/n8hQ0cQ9KrQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="tile is-parent is-4">
    <div class="tile is-child box">
      <div class="content">
        <iframe width="300" height="200" src="https://www.youtube.com/embed/dhj-DadcMsI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="tile is-parent">
    <div class="tile is-child box">
      <div class="content">
        <iframe width="300" height="200" src="https://www.youtube.com/embed/Dk2gA1wNXhI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div class="tile is-ancestor" style="padding-top:10px;">
  <div class="tile is-parent is-4">
    <div class="tile is-child box">
      <div class="content">
        <iframe width="300" height="200" src="https://www.youtube.com/embed/kl2JSfBPuE4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="tile is-parent is-4">
    <div class="tile is-child box">
      <div class="content">
        <iframe width="300" height="200" src="https://www.youtube.com/embed/KP2Ivf5LmPI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="tile is-parent is-4">
    <div class="tile is-child box">
      <div class="content">
        <iframe width="300" height="200" src="https://www.youtube.com/embed/1MeTr8hn998" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div class="tile is-ancestor" style="padding-top:10px;">
  <div class="tile is-parent is-4">
    <div class="tile is-child box">
      <div class="content">
        <iframe width="300" height="200" src="https://www.youtube.com/embed/5q4OE0HDM8k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

